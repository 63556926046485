<template>
  <div id="api-page" :class="classes">
    <GS1TableFlat
      v-model:search="searchString"
      v-bind="{
        fields: tableFields,
        data: tabledata,
        linkable: true,
        showRange: false,
        showHeader: true,
        showFooter: false,
        infinityScroll: false,
        rowActiveField: 'code',
        rowActive: [currentActiveApi],
      }"
      show-search-left
      @record-click="openApi"
    >
      <template #header>
        <div class="api-page__heading display-bold">API</div>
      </template>
      <template #token="{ item }">
        <div :class="{ 'gs1-table-flat__table-tr': user.gs1ApiAccess(item?.code) === 3 }">
          <GS1Btn
            v-if="user.gs1ApiAccess(item.code) == 1"
            success
            class="close-access__button"
            :to="{ name: 'gs1-api-access', params: { api: item.code } }"
          >
            Запросить доступ к API
          </GS1Btn>

          <div v-if="user.gs1ApiAccess(item.code) == 2">Ваш запрос на доступ к API находится на рассмотрении.</div>

          <div v-if="user.gs1ApiAccess(item.code) == 3 && item.no_token != 1">
            <div class="token__container">
              <CopyButton :string="user.tokens[item.code].token" />
              <GS1BtnIcon :icon="['refresh']" class="token__refresh-button" transparent small @click.stop="refreshToken(item.code)"></GS1BtnIcon>
              <span class="token__text">{{ user.tokens[item.code].token }}</span>
            </div>
          </div>

          <div v-if="user.gs1ApiAccess(item.code) == 4 || user.gs1ApiAccess(item.code) == 5" class="decline-access">
            В доступе отказано. Причина:
            {{ typeof user.declineReasons[item.code] !== 'undefined' ? user.declineReasons[item.code].comment : '' }}
          </div>
        </div>
      </template>
    </GS1TableFlat>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, Ref, ref } from 'vue';
import type { ComputedRef } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import CopyButton from '@/components/CopyButton.vue';
import Toast from '@/models/Toast';
import User from '@/modules/auth/models/User';
import type { GS1TableField } from '@gs1ru/web-components';

export default defineComponent({
  name: 'ApiPage',
  components: {
    CopyButton,
  },
  setup() {
    const store = useStore();

    const router = useRouter();
    const route = useRoute();
    const ViewMode: ComputedRef<string> = computed(() => store.state.ViewMode);

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<any> = computed(() => store.state.loading);

    /**
     * Объект авторизованного пользователя
     */
    const user: ComputedRef<User> = computed(() => store.state.auth.user);

    const currentActiveApi = computed(() => route.params.api);

    const searchString: Ref<string> = ref('');

    const openApi = (e: Event, api: any) => {
      if (user.value.gs1ApiAccess(api?.code) === 3) router.push({ name: 'gs1-api-request-index', params: { api: api.code } });
    };

    /**
     * Список API
     */
    const apiList: ComputedRef<Array<Record<string, any>>> = computed(() => {
      if (searchString.value && searchString.value.length)
        return store.state.vbgApi.apiList.filter((item: any) => {
          return item.name.toLowerCase().includes(searchString.value.toLowerCase()) || item.description.includes(searchString.value);
        });
      else return store.state.vbgApi.apiList;
    });

    const tabledata = computed(() => {
      return apiList.value.map((api: Record<string, any>) => {
        api.classes = user.value.gs1ApiAccess(api?.code) === 3 || api.no_token === 1 ? 'pointer' : '';
        return api;
      });
    });

    const tableFields: Array<GS1TableField> = [
      { label: 'Название', field: 'name', sort: false, class: [] },
      { label: 'Версия', field: 'version', sort: false, class: [] },
      { label: 'Описание', field: 'description', sort: false, class: [] },
      { label: 'Токен', field: 'token', sort: false, class: [], width: '600px', lineClamp: 1 },
    ];

    const classes: ComputedRef<{ [key: string]: boolean }> = computed((): { [key: string]: boolean } => {
      return {
        'api-page': true,
        ['mode-' + ViewMode.value]: true,
      };
    });

    /**
     * Обновление токена API
     *
     * @param api Код API
     */
    const refreshToken = (api: number) => {
      store.dispatch('vbgApi/refreshToken', api).then((success: boolean) => {
        if (success) {
          store.dispatch('showToast', new Toast('Токен обновлен', 'Ваш токен для доступа к API был успешно обновлен.', 'success'));
          store.dispatch('auth/getUser', true);
        }
      });
    };

    onMounted(() => {
      store.commit('setH1', 'Список API');
      store.dispatch('vbgApi/getApiList');
    });

    return {
      loading,
      user,
      apiList,
      tableFields,
      searchString,
      tabledata,
      classes,
      currentActiveApi,
      refreshToken,
      openApi,
    };
  },
});
</script>
<style lang="scss">
.api-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .api-page__heading {
    font-size: 14px;
    font-weight: 500;
    margin-right: var(--margin-x3);
    font-family: 'SF UI Display';
  }

  .token__container {
    display: flex;
    align-items: center;
    .token__refresh-button {
      margin-left: var(--margin-x2);
    }
    .gs1-element.gs1-btn-icon.transparent .gs1-icon {
      > i {
        background-color: var(--color-main-3);
      }
      &:hover > i {
      }
    }
  }

  .close-access__button {
    width: 230px;
  }

  .token__text {
    margin-left: var(--margin-x1);
  }

  .gs1-table-flat__table-tr.pointer {
    cursor: pointer;
  }

  .decline-access {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .gs1-element.gs1-table-flat {
    .gs1-table-flat__header {
      margin: 0 0 var(--gs1-margin-x3) 0;
    }
  }

  &.mode-dark {
    .api-page__heading {
      color: var(--color-main-f);
    }
    .token__container {
      .gs1-element.gs1-btn-icon.transparent .gs1-icon {
        > i {
          background-color: var(--color-main-f);
        }
        &:hover > i {
        }
      }
    }
  }
}
#api-page {
  .gs1-element.gs1-curtain {
    margin-right: var(--margin-x25);
  }
  .gs1-curtain__inner {
    padding-left: var(--padding-x3);
  }
}
</style>
