import { defineComponent, ref, computed, watch, inject } from 'vue';
import type { Ref, ComputedRef, WritableComputedRef } from 'vue';
import MainHeader from '@/components/MainHeader.vue';
import SideBarRight from '@/components/SideBarRight.vue';
import SideBars from '@/models/SideBars';
import LeftMenu from '@/components/LeftMenu.vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import useLayoutHelpers from '@/utils/useLayoutHelpers';
import type EventBus from '@/models/EventBus';

export default defineComponent({
  name: 'LayoutDefault',
  components: { MainHeader, SideBarRight, LeftMenu },
  setup() {
    const { openSideBar, closeSideBar } = useLayoutHelpers();
    const route = useRoute();
    const store = useStore();
    const ViewMode: ComputedRef<string> = computed(() => store.state.ViewMode);
    const matched = computed(() => route.matched[0]);
    const hasRightSidebar: ComputedRef<boolean> = computed((): boolean => !!matched?.value?.components?.right);
    const hasRightSidepanel: ComputedRef<boolean> = computed((): boolean => !!matched?.value?.components?.rightPanel);
    const sidePanelFixed: ComputedRef<boolean> = computed((): boolean => {
      return typeof route.meta?.fixedRightPanel !== 'undefined' && (route.meta?.fixedRightPanel as boolean);
    });
    const sidePanelActive: WritableComputedRef<boolean> = computed({
      get: (): boolean => {
        return store.state.sidePanelActive;
      },
      set: (value: boolean): void => {
        store.commit('setSidePanel', value);
      },
    });

    const sideBarVisibility: Ref<boolean> = ref(false);
    const sibarTimeOut: Ref<number> = ref(0);
    const showLeftMenu: Ref<boolean> = computed(() => store.state.auth.user.loggedIn);
    const bus: EventBus | undefined = inject('bus');

    const onSidePanelClose = () => {
      bus ? bus.emit('close-sidepanel') : null;
    };

    const classes: ComputedRef<{ [key: string]: boolean }> = computed((): { [key: string]: boolean } => {
      return {
        'layout-default__layout-container': true,
        ['mode-' + ViewMode.value]: true,
      };
    });

    watch(
      (): boolean => hasRightSidebar.value,
      (value): void => {
        clearTimeout(sibarTimeOut.value);

        if (value) {
          sideBarVisibility.value = true;
          sibarTimeOut.value = setTimeout(() => openSideBar(SideBars.right), 0);
        } else {
          closeSideBar(SideBars.right);
          sibarTimeOut.value = setTimeout(() => (sideBarVisibility.value = false), 510);
        }
      }
    );
    watch(
      (): boolean => hasRightSidepanel.value,
      (value): void => {
        if (value) {
          store.commit('openSidePanel');
        } else {
          store.commit('closeSidePanel');
        }
      }
    );

    return { sideBarVisibility, sidePanelActive, classes, sidePanelFixed, showLeftMenu, onSidePanelClose };
  },
});
