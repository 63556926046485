<template lang="html">
  <div v-if="gtin" :class="classes">
    <GS1Tabs v-if="!gtin.noInfo">
      <GS1TabsItem :active="activeTab == 'main'" @click="activeTab = 'main'">О товаре</GS1TabsItem>
      <GS1TabsItem :active="activeTab == 'license'" @click="activeTab = 'license'">О владельце GTIN</GS1TabsItem>
    </GS1Tabs>
    <div v-if="activeTab == 'main' && !gtin.noInfo" class="gtin-result__main">
      <div v-if="isImagePlaceholder" :class="{ 'gtin-result__image-container': true, placeholder__container: isImagePlaceholder }">
        <img v-if="gtin.productImageUrl.length == 1 && isImagePlaceholder" class="gtin-result__image" :src="logoImage" />
      </div>
      <div v-else :class="{ 'gtin-result__image-container': true, placeholder__container: isImagePlaceholder }">
        <img v-if="gtin.productImageUrl.length == 1 && !isImagePlaceholder" class="gtin-result__image" :src="productImages[0].value" />
        <Slider v-else class="gtin-result__image" :img="gtin.productImageUrl"></Slider>
      </div>
      <GS1AlertBlockInfo v-if="gtin?.gtinRecordStatus == 'DISCONTINUED'" warning icon="info-circle">
        <template #title>
          <span>Внимание!</span>
        </template>
        GTIN заблокирован владельцем. Точность и актуальность данных не гарантируются.
      </GS1AlertBlockInfo>
      <GS1AlertBlockInfo v-if="marking" warning icon="info-circle">
        <template #title>
          <span>Внимание!</span>
        </template>
        GTIN находится в стадии подготовки к обязательной маркировке. Возможны изменения данных.
      </GS1AlertBlockInfo>
      <GS1AlertBlock v-if="gtin?.gtinRecordStatus == 'INACTIVE'" accent alt icon="info-circle">
        Данные по GTIN могут быть неактуальны.
      </GS1AlertBlock>
      <div class="gtin-result__info">
        <h2>{{ productName ? productName : 'Нет данных' }}</h2>
        <div class="gtin-result__info__fields">
          <div class="gtin-result__info__field">
            <div class="gtin-result__info__field__label">GTIN</div>
            <div class="gtin-result__info__field__value">{{ gtin.gtin ? gtin?.gtin : 'Нет данных' }}</div>
          </div>
          <div class="gtin-result__info__field">
            <div class="gtin-result__info__field__label">Товарный знак</div>
            <div class="gtin-result__info__field__value">
              <span v-for="brandName in gtin?.brandName" :key="`brand-name-${brandName.language}`">
                {{ brandName?.language && brandName?.value ? brandName?.language + ': ' + brandName?.value : 'Нет данных' }}
              </span>
            </div>
          </div>
          <div class="gtin-result__info__field">
            <div class="gtin-result__info__field__label">Описание</div>
            <div class="gtin-result__info__field__value">
              <span v-for="productDescription in gtin?.productDescription" :key="`product-description-${productDescription?.language}`">
                {{
                  productDescription?.language && productDescription?.value
                    ? productDescription?.language + ': ' + productDescription?.value
                    : 'Нет данных'
                }}
              </span>
            </div>
          </div>
          <div v-if="productImagesTiff.length" class="gtin-result__info__field">
            <div class="gtin-result__info__field__label">Ссылка на картинку товара</div>
            <div class="gtin-result__info__field__value">
              <a v-for="(productImgTiff, imgIndex) in productImagesTiff" :key="imgIndex" :href="productImgTiff" target="_blank">
                {{ productImgTiff }}
              </a>
            </div>
          </div>
          <div class="gtin-result__info__field">
            <div class="gtin-result__info__field__label">Категория GPC</div>
            <div class="gtin-result__info__field__value">{{ gtin?.gpcCategory?.name ? gtin?.gpcCategory?.name : 'Нет данных' }}</div>
          </div>
          <div class="gtin-result__info__field">
            <div class="gtin-result__info__field__label">Количество/вес</div>
            <div class="gtin-result__info__field__value">
              {{ gtin?.netContent?.value && gtin?.netContent?.unitCode ? gtin?.netContent?.value + ' ' + gtin?.netContent?.unitCode : 'Нет данных' }}
            </div>
          </div>
          <div class="gtin-result__info__field">
            <div class="gtin-result__info__field__label">Рынок сбыта</div>
            <div class="gtin-result__info__field__value">{{ gtin?.countryOfSaleCode ? gtin?.countryOfSaleCode[0].alpha3 : 'Нет данных' }}</div>
          </div>
        </div>
      </div>
      <div class="gtin-result__note font-black">
        <span v-if="gtin?.isComplete">Предоставлена полная информация для верификации</span>
        <span>Данные предоставлены: {{ gtin.licenseInfo?.licenseeName ? gtin.licenseInfo?.licenseeName : 'Нет данных' }}</span>
        <span class="gtin-result__note__last-update font-black"> Последнее обновление {{ gtin.dateUpdated ? gtin.dateUpdated : 'Нет данных' }} </span>
      </div>
      <ResultBlock v-if="gtin?.links.length > 0">
        <template #title>Полезные ссылки</template>
        <template #default>
          <div class="gtin-result__info">
            <div class="gtin-result__info__fields gtin-result__info__links">
              <div v-for="(link, i) in gtin?.links" :key="i" class="gtin-result__info__field">
                <div class="gtin-result__info__field__label">
                  <GS1BtnIcon :icon="['link-alt']" no-margin></GS1BtnIcon>
                </div>
                <a target="_blank" :href="link.href" class="gtin-result__info__field__value">
                  <div class="label">{{ link.title }}</div>
                  <div class="display-secondary link">{{ link.href }}</div>
                  <div class="display-secondary languages">Язык: {{ link.hreflang.join(', ') }}</div>
                </a>
              </div>
            </div>
          </div>
        </template>
      </ResultBlock>
    </div>

    <div v-if="activeTab == 'license' || gtin.noInfo" class="gtin-result__license">
      <h2>О владельце GTIN</h2>
      <div class="gtin-result__info">
        <div class="gtin-result__info__fields">
          <div v-if="!activeStatus" class="gtin-result__info__field">
            <div class="gtin-result__info__field__label">Статус</div>
            <div class="gtin-result__info__field__value">Неактивно</div>
          </div>
          <div class="gtin-result__info__field">
            <div class="gtin-result__info__field__label">Название организации</div>
            <div class="gtin-result__info__field__value">{{ gtin.licenseInfo?.licenseeName ? gtin?.licenseInfo.licenseeName : 'Нет данных' }}</div>
          </div>
          <div v-if="activeStatus" class="gtin-result__info__field">
            <div class="gtin-result__info__field__label">Адрес</div>
            <div class="gtin-result__info__field__value">{{ fullAddress.length > 0 ? fullAddress : 'Нет данных' }}</div>
          </div>
          <div v-if="activeStatus" class="gtin-result__info__field">
            <div class="gtin-result__info__field__label">Веб-сайт</div>
            <div class="gtin-result__info__field__value">
              <template v-if="licenseSites.length > 0">
                <a v-for="(site, index) in licenseSites" :key="index" :href="site" target="_blank">
                  {{ site }}
                </a>
              </template>
              <span v-else>Нет данных</span>
            </div>
          </div>
          <div v-if="activeStatus" class="gtin-result__info__field">
            <div class="gtin-result__info__field__label">E-mail</div>
            <div class="gtin-result__info__field__value">
              <template v-if="licenseEmails.length > 0">
                <a v-for="(email, index) in licenseEmails" :key="index" :href="'mailto:' + email">
                  {{ email }}
                </a>
              </template>
              <span v-else>Нет данных</span>
            </div>
          </div>
          <div v-if="activeStatus" class="gtin-result__info__field">
            <div class="gtin-result__info__field__label">Телефон</div>
            <div class="gtin-result__info__field__value">
              <template v-if="licensePhones.length > 0">
                <a v-for="(phone, index) in licensePhones" :key="index" :href="'tel:' + phone">
                  {{ phone }}
                </a>
              </template>
              <span v-else>Нет данных</span>
            </div>
          </div>
          <div v-if="activeStatus" class="gtin-result__info__field">
            <div class="gtin-result__info__field__label">Префикс</div>
            <div class="gtin-result__info__field__value">
              {{ typeof gtin.licenseInfo?.licenceKey !== 'undefined' ? gtin.licenseInfo.licenceKey : 'Нет данных' }}
            </div>
          </div>
          <div v-if="activeStatus" class="gtin-result__info__field">
            <div class="gtin-result__info__field__label">GLN</div>
            <div class="gtin-result__info__field__value">
              {{ typeof gtin.licenseInfo?.licenseeGLN !== 'undefined' ? gtin.licenseInfo.licenseeGLN : 'Нет данных' }}
            </div>
          </div>
          <div class="gtin-result__info__field">
            <div class="gtin-result__info__field__label">Источник информации</div>
            <div class="gtin-result__info__field__value">
              {{ gtin?.licenseInfo?.primaryMO?.moName ? gtin?.licenseInfo?.primaryMO?.moName : 'Нет данных' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, inject, computed, watch, ref, onMounted, onUnmounted } from 'vue';
import Slider from '@/components/Slider.vue';
import type { ComputedRef, Ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import type EventBus from '@/models/EventBus';
import ResultBlock from '@/components/ResultBlock.vue';

export default defineComponent({
  name: 'GtinResult',
  components: {
    Slider,
    ResultBlock,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const bus: EventBus | undefined = inject('bus');

    const productImages: Ref<Record<string, string>[]> = ref([]);
    const ViewMode: ComputedRef<string> = computed(() => store.state.ViewMode);

    const user = computed(() => store.state.auth.user);

    /**
     * Запрос  конвертации tiff изображений
     *
     * @param imagePath
     * @param gtinNumber
     */
    const convertTiff = (imageUrl: string, gtinNumber: string, index: string) => {
      return store.dispatch('gtinSearch/convertTiff', {
        imageUrl: imageUrl,
        gtinNumber: gtinNumber,
        index: index,
      });
    };

    const logoImage = computed(() => {
      if (ViewMode.value === 'dark') return require('./../../../assets/placeholderDark.svg');
      else if (ViewMode.value === 'light') return require('./../../../assets/placeholder.svg');
      else return require('./../../../assets/placeholder.svg');
    });

    const isImagePlaceholder = computed(() => {
      if (productImages.value?.at(0)?.value === '/images/placeholder.png' || productImages.value?.at(0)?.value.includes('/img/placeholderDark'))
        return true;
      else return false;
    });

    /**
     * gtins
     */
    const activeTab: Ref<string> = ref('main');
    const gtins = computed(() => store.state.gtinSearch.gtins);

    const sortByLang = (a: Record<string, string>, b: Record<string, string>) => {
      const lang1 = a.language;
      const lang2 = b.language;

      if (lang1 == 'ru' || (lang1 == 'en' && lang2 != 'ru')) {
        return -1;
      }
      if (lang2 == 'ru' || (lang2 == 'en' && lang1 != 'ru')) {
        return 1;
      }

      return 0;
    };

    const gtin: ComputedRef<any> = computed(() => {
      store.state.gtinSearch?.gtin?.productImageUrl?.forEach((gtinImage: any, index: number) => {
        if (gtinImage?.value == 'Маркировка') productImages.value[index] = { value: logoImage.value };
        else if (
          String(gtinImage?.value).split('.').pop()?.split('?')[0] == 'tiff' ||
          (typeof gtinImage?.valueOriginal != 'undefined' && String(gtinImage?.valueOriginal).split('.').pop()?.split('?')[0] == 'tiff')
        ) {
          convertTiff(gtinImage.valueOriginal, store.state.gtinSearch.gtin.gtin, index.toString()).then((newImageUrl: string) => {
            productImages.value[index].value = newImageUrl;
          });

          productImages.value[index] = { value: logoImage.value };
        } else productImages.value[index] = gtinImage;
      });

      const gtinInfo = store.state.gtinSearch.gtin;

      if (typeof gtinInfo != 'undefined') {
        gtinInfo.links = [];
        if (typeof gtinInfo.linkset !== 'undefined') {
          for (const index in gtinInfo.linkset) {
            const value = gtinInfo.linkset[index];
            if (value && Array.isArray(value)) {
              value.forEach((link: Record<string, any>) => {
                gtinInfo.links.push(link);
              });
            }
          }
        }
      }

      gtinInfo?.brandName?.sort(sortByLang);
      gtinInfo?.productDescription?.sort(sortByLang);

      return gtinInfo;
    });

    const licenseSites: ComputedRef<string[]> = computed(() => {
      const sites: string[] = [];

      if (typeof gtin.value.licenseInfo?.contactPoint !== 'undefined') {
        gtin.value.licenseInfo.contactPoint.forEach((contact: Record<string, string>) => {
          if (!sites.includes(contact.website) && typeof contact.website !== 'undefined' && contact.website.length > 0 && sites.length < 3)
            sites.push(contact.website);
        });
      }

      return sites;
    });

    const licensePhones: ComputedRef<string[]> = computed(() => {
      const phones: string[] = [];

      if (typeof gtin.value.licenseInfo?.contactPoint !== 'undefined') {
        gtin.value.licenseInfo.contactPoint.forEach((contact: Record<string, string>) => {
          if (!phones.includes(contact.telephone) && typeof contact.telephone !== 'undefined' && contact.telephone.length > 0 && phones.length < 3)
            phones.push(contact.telephone);
        });
      }

      return phones;
    });

    const licenseEmails: ComputedRef<string[]> = computed(() => {
      const emails: string[] = [];

      if (typeof gtin.value.licenseInfo?.contactPoint !== 'undefined') {
        gtin.value.licenseInfo.contactPoint.forEach((contact: Record<string, string>) => {
          if (!emails.includes(contact.email) && typeof contact.email !== 'undefined' && contact.email.length > 0 && emails.length < 3)
            emails.push(contact.email);
        });
      }

      return emails;
    });

    const productImagesTiff = computed(() =>
      gtin.value?.productImageUrl?.filter((gtinImage: any) => String(gtinImage.value).split('.')?.pop() == 'tiff').map((i: any) => i.value)
    );
    const queryGtin = computed(() => route.params?.gtin);

    /**
     * Название товара
     */
    const productName: ComputedRef<string> = computed(() => {
      if (typeof gtin.value.productDescription !== 'undefined') {
        let name = gtin.value.productDescription.find((name: Record<string, string>) => name.language == 'ru');
        if (!name) name = gtin.value.productDescription[gtin.value.productDescription.length - 1];
        return name.value;
      }
      return '';
    });

    /**
     * Полный адрес предприятия
     */
    const fullAddress: ComputedRef<string> = computed(() => {
      const fullAddress = [];

      // Регион
      if (
        typeof gtin.value.licenseInfo.address?.addressRegion?.value !== 'undefined' &&
        gtin.value.licenseInfo.address?.addressRegion?.value.length > 0
      ) {
        fullAddress.push(gtin.value.licenseInfo.address.addressRegion.value);
      }
      // Город
      if (
        typeof gtin.value.licenseInfo.address?.addressLocality?.value !== 'undefined' &&
        gtin.value.licenseInfo.address?.addressLocality?.value.length > 0
      ) {
        fullAddress.push(gtin.value.licenseInfo.address.addressLocality.value);
      }
      // Улица
      if (
        typeof gtin.value.licenseInfo.address?.streetAddress?.value !== 'undefined' &&
        gtin.value.licenseInfo.address?.streetAddress?.value.length > 0
      ) {
        fullAddress.push(gtin.value.licenseInfo.address.streetAddress.value);
      }

      return fullAddress.join(', ');
    });

    /**
     * Активность
     */
    const activeStatus: ComputedRef<boolean> = computed(() => {
      if (typeof gtin.value.licenseInfo.licenceStatus !== 'undefined') {
        return gtin.value.licenseInfo.licenceStatus != 'INACTIVE' || user.value.pro;
      }

      return false;
    });

    const classes: ComputedRef<{ [key: string]: boolean }> = computed((): { [key: string]: boolean } => {
      return {
        'gtin-result__container': true,
        ['mode-' + ViewMode.value]: true,
      };
    });

    const marking: ComputedRef<boolean> = computed(() => {
      let markingStatus = false;
      gtin.value?.productImageUrl.forEach((gtinImage: any) => {
        if (gtinImage.value == 'Маркировка') {
          markingStatus = true;
        }
      });
      return markingStatus;
    });

    const onCloseSidePanel = () => {
      router.push({ name: 'gtin-search-search' });
    };

    const autoCloseSidePanel = () => {
      if (!gtins?.value?.length) onCloseSidePanel();
      return;
    };
    autoCloseSidePanel();

    onMounted(() => {
      // Слушатель события клика на кнопке закрытия правой панели
      if (bus) bus.on('close-sidepanel', onCloseSidePanel);
    });

    onUnmounted(() => {
      if (bus) bus.off('close-sidepanel', onCloseSidePanel);
    });
    const getGtin = () =>
      store.commit(
        'gtinSearch/setGtin',
        gtins.value.find((_gtin: any) => _gtin.gtin == queryGtin.value)
      );

    getGtin();

    watch(queryGtin, () => getGtin());

    return {
      activeTab,
      gtins,
      gtin,
      productName,
      productImages,
      productImagesTiff,
      fullAddress,
      marking,
      licenseSites,
      licensePhones,
      licenseEmails,
      classes,
      isImagePlaceholder,
      logoImage,
      activeStatus,
    };
  },
});
</script>
<style lang="scss">
.gtin-result__container {
  .gs1-element.gs1-tabs-item {
    flex: 1 1;
  }

  &.mode-dark {
    .gtin-result__info {
      border-color: var(--color-main-22);
      h2 {
        border-color: var(--color-main-22);
        margin-bottom: 0;
      }

      .gtin-result__info__field {
        color: var(--color-main-f);
        .gs1-btn-icon {
          background-color: var(--color-additional-17);
        }
        &:hover {
          background-color: var(--color-additional-17);
          .gtin-result__info__field__value {
            color: var(--color-main-f);
          }
        }
        &.vertical {
          border-color: var(--color-main-22);
        }
      }
      .gtin-result__info__links {
        .label,
        .link,
        .languages {
          color: var(--color-main-4);
        }
        .gtin-result__info__field {
          &:hover {
            .label,
            .link {
              color: var(--color-main-f);
            }
          }
        }
      }
    }
    .gtin-result__note {
      color: var(--color-main-4);
      .gtin-result__note__last-update {
        color: var(--color-main-4);
      }
    }
    .placeholder__container {
      background-color: var(--color-main-27);
      border: 1px solid var(--color-main-22);
      .gtin-result__image {
        width: 48px;
        height: 48px;
      }
    }
  }
}
.gtin-result__image-container {
  height: 160px;
  width: 160px;
  margin: var(--margin-x3) 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-main-f);
  border-radius: var(--border-radius-x22);
  .gtin-result__image {
    max-width: 100%;
    max-height: 100%;
  }
}
.placeholder__container {
  background-color: var(--color-main-f);
  border: 1px solid var(--color-main-10);
  .gtin-result__image {
    width: 48px;
    height: 48px;
  }
}
.gtin-result__info {
  border: var(--border-thin) solid var(--color-main-10);
  border-radius: var(--border-radius-x2);
  h2 {
    font-weight: 500;
    font-size: 14px;
    padding: var(--padding-x3);
    border-bottom: var(--border-thin) solid var(--color-main-10);
  }
  .gtin-result__info__fields {
    padding: var(--padding-x2);
  }
  .gtin-result__info__field {
    display: flex;
    align-items: center;
    padding: var(--padding-x2);
    border-radius: var(--border-radius-x1);
    background-color: transparent;
    transition: background-color var(--transition-super-fast-smooth);
    color: var(--color-main-3);
    cursor: pointer;
    &:hover {
      background-color: var(--color-main-10);
      .gtin-result__info__field__value {
        color: var(--color-main-2);
      }
    }
    + .gtin-result__info__field {
      margin-top: var(--margin-x2);
    }
    .gtin-result__info__field__label {
      flex: 0 0 40%;
      width: 40%;
      font-weight: 500;
      .gs1-btn-icon {
        background-color: var(--color-main-10);
        margin-right: var(--margin-x2);
        .gs1-icon__icon {
          background-color: var(--color-main-8) !important;
        }
      }
    }
    .gtin-result__info__field__value {
      font-size: 13px;
      flex: 0 0 60%;
      width: 60%;
      a {
        color: inherit;
        text-decoration: underline;
        display: block;
        word-wrap: break-word;
        + a {
          margin-top: var(--margin-x2);
        }
      }
      > span {
        display: block;
      }
    }
    &.vertical {
      flex-direction: column;
      align-items: flex-start;
      border-radius: 0;
      + .gtin-result__info__field {
        margin-top: 0;
        border-top: var(--border-thin) solid var(--color-main-10);
      }
      .gtin-result__info__field__label,
      .gtin-result__info__field__value {
        flex: 0 0 100%;
        width: 100%;
      }
      .display-secondary {
        margin-top: var(--margin-x1);
      }
    }
  }
  .gtin-result__info__links {
    .gtin-result__info__field__label {
      width: auto;
      flex-basis: auto;
    }
    .gtin-result__info__field__value {
      width: auto;
      flex: 1 1 auto;
      color: var(--color-main-3);
      .label,
      .link {
        margin-bottom: var(--margin-x15);
      }
      .link {
        text-decoration: underline;
      }
      .label {
        font-size: 13px;
        font-weight: 500;
      }
      .languages {
        color: var(--color-main-3);
        font-weight: 500;
      }
    }
  }
}
.gtin-result__note {
  font-size: 13px;
  display: flex;
  flex-direction: column;
  margin-top: var(--margin-x25);
  .gtin-result__note__last-update {
    font-size: 12px;
  }
  span + span {
    margin-top: var(--margin-x25);
  }
}

.gtin-result__license {
  h2 {
    font-weight: 600;
    font-size: 13px;
    margin: 0 0 var(--margin-x3) 0;
  }
  .gs1-tabs + & h2 {
    margin-top: var(--margin-x3);
  }
}
</style>
