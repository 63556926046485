<template lang="html">
  <div v-if="gtin" :class="classes">
    {{ gtin?.gtin }}
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue';
import type { ComputedRef } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'GtinResultHeader',
  setup() {
    const store = useStore();
    const ViewMode: ComputedRef<string> = computed(() => store.state.ViewMode);

    const gtin: ComputedRef<any> = computed(() => store.state.gtinSearch.gtin);
    const classes: ComputedRef<Record<string, boolean>> = computed(() => {
      return {
        error: gtin.value.hasError,
        success: !gtin.value.hasError,
        warning: gtin.value.gtinRecordStatus == 'DISCONTINUED',
        'gtin-result-header': true,
        ['mode-' + ViewMode.value]: true,
      };
    });

    return {
      gtin,
      classes,
    };
  },
});
</script>
<style lang="scss" scoped>
.gtin-result-header {
  padding: var(--padding-x1) var(--padding-x2);
  border-radius: var(--border-radius-x2);
  height: 32px;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  &::before {
    content: '';
    width: 2px;
    height: 10px;
    margin-right: var(--margin-x1);
  }

  &.success {
    background-color: var(--color-main-15);
    &::before {
      background-color: var(--color-main-7);
    }
  }

  &.error {
    background-color: var(--color-main-16);
    &::before {
      background-color: var(--color-main-5);
    }
  }

  &.warning {
    background-color: var(--color-main-17);
    &::before {
      background-color: var(--color-main-18);
    }
  }
  &.mode-dark {
    color: var(--color-main-f);
    line-height: 21px;

    &.success {
      background-color: var(--color-additional-14);
    }

    &.error {
      background-color: var(--color-danger-950);
    }

    &.warning {
      background-color: var(--color-main-26);
    }
  }
}
</style>
